import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./AppCard.css";
import { useNavigate } from "react-router-dom";

interface AppCardProps {
  title: string;
  text: string;
  imageUrl?: string;
  buttonLabel: string;
  buttonUrl: string;
  message: string;
  buttondisable: boolean;
}

const AppCard: React.FC<AppCardProps> = ({
  title,
  text,
  imageUrl,
  buttonLabel,
  buttonUrl,
  message,
  buttondisable,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!buttondisable) {
      navigate(buttonUrl);
    }
  };

  return (
    <Card className="h-100 d-flex flex-column" style={{ width: "18rem" }}>
      {/*<Card.Img variant="top" src={imageUrl} alt="Card image" />*/}
      {imageUrl ? (
        <Card.Img
          variant="top"
          src={imageUrl}
          alt="Card image"
          className="card-img-top"
        />
      ) : (
        <div className="card-img-top">
          <div className="coming-soon-overlay">{message}</div>
        </div>
      )}
      <Card.Body className="d-flex flex-column">
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Button
          variant="primary"
          onClick={handleButtonClick}
          disabled={buttondisable}
        >
          {buttonLabel}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default AppCard;
