import "./IphoneMock.css"
import React, { useRef, useEffect, useState } from 'react';


interface IphoneMockProps {
    src: string;
    alt: string;
  }

const IphoneMock: React.FC<IphoneMockProps> = ({ src, alt }) => {
    const imgRef = useRef(null);
    const [isZoomed, setIsZoomed] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsZoomed(true);
          } else {
            setIsZoomed(false);
          }
        },
        {
          threshold: 0.5,
        }
      );
  
      if (imgRef.current) {
        observer.observe(imgRef.current);
      }
  
      return () => {
        if (imgRef.current) {
          observer.unobserve(imgRef.current);
        }
      };
    }, []);
  
    return (
        <div className="outer-container p-5 bg-gray-100">
        <div className="container-iphone-and-text">
         <div className="info-text ">
                <h2 className="font-bold mb-4 w-full h-auto">About OfBit</h2>
                <p>
                    We are a team of developers who are passionate about creating apps that help people reach their goals. Our apps are designed to be simple and easy to use, so you can focus on what matters most.
                </p>
            </div>
          <div
                className={`zoom-container ${isZoomed ? 'zoomed' : ''}`}
            ref={imgRef}
          >
            <img src={src} alt={alt} className="w-full h-auto" />
          </div>
        </div>
        </div>
      );
    };
  
  export default IphoneMock;