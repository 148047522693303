import React from "react";
import "./Footer.css";
import { FaApple, FaGooglePlay } from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <div
      className="footer bg-gray-800 text-white p-4 flex justify-center items-center"
      style={{ maxHeight: "100rem" }}
    >
      <div className="icon-container flex justify-center items-center mb-4">
        <a
          href="https://apps.apple.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="icon mx-2"
        >
          <FaApple className="h-10 w-10" />
        </a>
        <a
          href="https://play.google.com/store"
          target="_blank"
          rel="noopener noreferrer"
          className="icon mx-2"
        >
          <FaGooglePlay className="h-10 w-10" />
        </a>
      </div>
      <hr className="footer-line" />
      <p className="copyright">
        © {new Date().getFullYear()}, OFBIT AS, NO 934080327
      </p>
    </div>
  );
};

export default Footer;
export {};
