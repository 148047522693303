import React from "react";
import "./TimerApp.css";
import ImageCarousel from "./ImageCarousel";

const TimerApp: React.FC = () => {
  return (
    <div>
      <div className="info mt-5">
        <h1 className="align-center">Timer App</h1>
        <p>
          This app helps you set timers for your workout sessions and track your
          progress over time. It's designed to help you stay consistent and
          achieve your fitness goals.
        </p>
        <p>Features include:</p>
        <ul style={{ listStyleType: "circle" }}>
          <li>Customizable workout timers</li>
          <li>View activity in the calender</li>
        </ul>
        <p>Planned to release before the end of 2024!</p>
      </div>
      <ImageCarousel></ImageCarousel>
    </div>
  );
};

export default TimerApp;
