import AppCard from "./AppCard";
import "./Home.css";
import ImageSlider from "./ImageSlider";
import IphoneMock from "./IphoneMock";

interface HomeProps {
  refs: {
    home: React.RefObject<HTMLDivElement>; // Define specific ref types for home, about, and apps
    about: React.RefObject<HTMLDivElement>;
    apps: React.RefObject<HTMLDivElement>;
  };
}

const Home: React.FC<HomeProps> = ({ refs }) => {
  return (
    <>
      <ImageSlider />
      <div ref={refs.about}>
        {" "}
        {/* Adjust the container height for scrolling */}
        <IphoneMock src={"/images/Iphone-with-timers.png"} alt="iPhone" />
      </div>
      <div ref={refs.apps} className="apps-header container mt-5">
        <h2 className="text-center">Our Apps</h2>
      </div>
      <div className="appcard-container container mt-5 flex-grow-1 d-flex justify-content-center align-items-center">
        <div className="row w-100 mt-3">
          <div className="appcard col-md-4 d-flex justify-content-center mb-4">
            <AppCard
              title="Timer App"
              text="A workout app based around timers. Get in shape and track your progress!"
              buttonLabel="Learn more"
              buttondisable={false}
              buttonUrl="/timer-app"
              message="Coming soon"
            />
          </div>
          <div className="appcard col-md-4 d-flex justify-content-center mb-4">
            <AppCard
              title="Tracker App"
              text="Need to keep track of goals? Use the tracker app. It's simple and easy to use!"
              //imageUrl="https://via.placeholder.com/150"
              buttonLabel="Learn more"
              buttondisable={true}
              buttonUrl="#"
              message="Coming in 2025"
            />
          </div>
          <div className="appcard col-md-4 d-flex justify-content-center mb-4">
            <AppCard
              title="Gym Notes"
              text="A workout app for counting reps and sets in the gym. Get in shape and reach your goals!"
              //imageUrl="https://via.placeholder.com/150"
              buttonLabel="Learn more"
              buttondisable={true}
              buttonUrl="#"
              message="Coming in 2025"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
