import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";

interface NavBarTopProps {
  refs: {
    home: React.RefObject<HTMLDivElement>;
    about: React.RefObject<HTMLDivElement>;
    apps: React.RefObject<HTMLDivElement>;
  };
}

const NavBarTop: React.FC<NavBarTopProps> = ({ refs }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navbarHeight = 90; // height of the navbar

  const scrollToSection = (section: "home" | "about" | "apps") => {
    const sectionElement = refs[section].current;
    if (sectionElement) {
      const sectionTop = sectionElement.getBoundingClientRect().top;
      const scrollPosition = window.scrollY + sectionTop - navbarHeight;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleNavClick = (section: "home" | "about" | "apps") => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => scrollToSection(section), 300); // Small delay for smooth navigation
  };
  return (
    <>
      <Navbar expand="lg" className="fixed-top border-bottom border-gray-300">
        <Navbar.Brand href="#home">
          <img
            src="/images/ofbit-logo-normal-navbar.png"
            width="80"
            height="80"
            className="d-inline-block align-top"
            alt="Logo"
            onClick={() => handleNavClick("home")}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" onClick={() => handleNavClick("home")}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => handleNavClick("about")}>
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/" onClick={() => handleNavClick("apps")}>
              Our Apps
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBarTop;
