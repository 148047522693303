import Carousel from "react-bootstrap/Carousel";
import './ImageCarousel.css'
//import ExampleCarouselImage from 'components/ExampleCarouselImage';

function ImageCarousel() {
  return (
    <div className="carousel-wrapper"> {/* Add a wrapper div */}
        <Carousel  interval={null} variant="dark">
            <Carousel.Item className="item">
                <img
                className="slide-image mx-auto h-64" // Center and make the image smaller
                src="/images/screenshots-timerapp/ScreenShot1.png"
                alt=""
                />
                <Carousel.Caption>
                <h3></h3>
                <p>Create a timer</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="item">
                <img
                className="slide-image mx-auto h-64" // Center and make the image smaller
                src="/images/screenshots-timerapp/ScreenShot2.png"
                alt=""
                />
                <Carousel.Caption>
                <h3></h3>
                <p>Access different timers</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="item">
                <img
                className="slide-image mx-auto h-64" // Center and make the image smaller
                src="/images/screenshots-timerapp/ScreenShot5.png"
                alt=""
                />
                <Carousel.Caption>
                <h3></h3>
                <p>Start a timer!</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="item">
                <img
                className="slide-image mx-auto h-64" // Center and make the image smaller
                src="/images/screenshots-timerapp/ScreenShot6.png"
                alt=""
                />
                <Carousel.Caption>
                <h3></h3>
                <p>Work!</p>
                </Carousel.Caption>
            </Carousel.Item>
                    <Carousel.Item  className="item">
                <img
                className=" slide-image mx-auto h-64" // Center and make the image smaller
                src="/images/screenshots-timerapp/ScreenShot3.png"
                alt=""
                />
                <Carousel.Caption>
                <h3></h3>
                <p>
                    View your activity in the calender
                </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  );
}

export default ImageCarousel;
