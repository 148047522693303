import React, { useRef } from "react";
import "./App.css";
import NavBarTop from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import TimerApp from "./components/TimerApp";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";

const AppContent: React.FC = () => {
  const homeRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const appsRef = useRef<HTMLDivElement>(null);

  const refs = {
    home: homeRef,
    about: aboutRef,
    apps: appsRef,
  };

  /*
  const navigate = useNavigate();
  const location = useLocation();

  
  const handleNavClick = (section: 'home' | 'about' | 'apps') => {
    const navbarHeight = 90; // height of the navbar
    console.log('testing');
    console.log(location.pathname);
    if (location.pathname !== '/') {
      console.log("pathname: ",location.pathname);
      console.log("scroll", refs[section].current);
      navigate('/');
    }

    const sectionElement = refs[section].current;
    if (sectionElement) {
      const sectionTop = sectionElement.getBoundingClientRect().top;
      const scrollPosition = window.scrollY + sectionTop - navbarHeight;
      console.log("scrollPosition: " + scrollPosition);
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  };
  */

  return (
    <>
      <NavBarTop refs={refs} />
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <div ref={homeRef}>
                <Home refs={refs} />
              </div>
            </div>
          }
        />
        <Route path="/timer-app" element={<TimerApp />} />
      </Routes>
      <Footer />
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <AppContent /> {/* All logic within the Router context */}
      </div>
    </Router>
  );
};

export default App;
